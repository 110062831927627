(function ($) {
  bindMarketSelectorEvents();
})(jQuery);
function getMarketSelectorHtmlCallback() {
  if (breakpoint.value === 'md' || breakpoint.value === 'lg') {
    if ($(".pageHeader[data-transparent]").length) {
      $(".pageHeader").removeClass("is-transparent");
      $(".pageHeader-searchOverlay").removeClass("is-visible");
    }
  }
  if (breakpoint.value === 'xs' || breakpoint.value === 'sm') {
    setTimeout(function () {
      $(".Header.menu-active").scrollTop($(".Header.menu-active")[0].scrollHeight);
    }, 200);
  }
}
function bindMarketSelectorEvents() {
  $(".toggleMarketSelector").click(function (e) {
    e.preventDefault();
    var $marketSelectorPlaceholder = $(this).closest(".is-marketSelector").find(".marketselector-menu"),
      theme = $("body").data("theme");
    if ($marketSelectorPlaceholder.html().length === 0 || $marketSelectorPlaceholder.hasClass("has-error")) {
      $marketSelectorPlaceholder.html("<li class=\"u-overflowHidden text-center u-padding15\"><img src=\"/ui/" + theme + "/img/loader.svg\" class=\"u-loader\" /></li>");
      $.ajax({
        data: {
          sitecoreItemId: $("body").data("sc-item"),
          sitecoreLanguageId: $("body").data("sc-language"),
          requestUrl: window.location.href,
          isGlobalDometic: $("body").hasClass("is-globaldometic")
        },
        type: "post",
        url: "/api/marketlanguagesdometic/marketlanguageselectorhtml",
        error: function error() {
          $marketSelectorPlaceholder.html("<li class=\"navigationMainComponent-xhrError\">ERROR: Could not load market selector</li>");
        },
        success: function success(data) {
          $marketSelectorPlaceholder.html(data.Html);
          getMarketSelectorHtmlCallback();
        }
      });
    } else {
      getMarketSelectorHtmlCallback();
    }
  });
}

//Open selection and stop propagation
$('body').on("click", ".is-marketSelector .dropdown-menu .Header-dropdown-listLink", function (e) {
  e.stopPropagation();
  $(this).toggleClass("is-expanded");
});

// Set market and langauge cookies
$('body').on("click", ".marketselector-menu-container .Header-dropdown-listLink", function (e) {
  e.preventDefault();
  setLanguageAndMarketCookies(this);
});
function setLanguageAndMarketCookies(e) {
  var splitted = $(e).attr("href").split("/");
  var language = splitted[1];
  var market = $(e).data("market");
  var sitecoreItem = $("body").data("sc-item");
  $.ajax({
    type: "POST",
    url: "/post-setmarketandlanguagecookies",
    data: {
      market: market,
      language: language,
      currentSitecoreItem: sitecoreItem
    },
    success: function success(data) {
      window.location = $(e).attr("href");
    }
  });
}